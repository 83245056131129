<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
// ! Agregar versiones para los cargos
export default {
  name: "PerfilCargo",

  async mounted() {
    let { id, empresa } = JSON.parse(localStorage.setPersonaAct);

    let persona = JSON.parse(localStorage.setPersonaAct);
    let ubicacionPersonaData = await this.$store.dispatch("hl_get", {
      path: "PersonaUbicacion/GetByPersona/" + persona.id,
    });
    this.sucursal = ubicacionPersonaData.sucursal;

    this.empresa = empresa;
    const personaId = id;

    const reponseUbicacion = await this.$store.getters.fetchGet({
      path: `PersonaUbicacion/GetByPersonaId/${personaId}`,
    });
    let responseUbicacion = await reponseUbicacion.json();
    const cargo = responseUbicacion.cargo;
    this.reponseUbicacion = responseUbicacion.sucursal;
    // console.log(cargo);
    await this.getFuncionesResponsabilidadYCompetencias(cargo.id);
    await this.getNivelCompetenciaComplejidad();
    //await this.ObtenerCargoById();
    await this.obtenerSucursales();
    const responseCargoEditado = await this.$store.getters.fetchGet({
      path: `Cargo/todoSobreElCargo/${cargo.id}`,
    });
    const cargoIntroduction = await responseCargoEditado.json();
    this.cargo = cargoIntroduction.cargo;
    this.competencias = cargoIntroduction.competencias;
    // console.log('competencias', cargoIntroduction.competencias);

    let responsePerfilCargo = null;
    let perfilCargo = null;

    /*this.$store.getters
      .fetchGet({ path: `perfilcargo/consultarPerfilCargoAll/${cargo.id}` })
      .then((response) => {
        if (response.status == 404) {
          alert("error no tiene perfil creado");
          return {};
        } else if (response.status == 200) {
          return response.json();
        }
      })
      .then(async(result) => {
         console.log("result", result);
        perfilCargo = await result.json();
        console.log("Perfil sin responsabilidadesSSSSSSSSSSSSSSSSSS",result)
        this.perfilCargo = perfilCargo;


      })
      .catch((error) => {
        console.log(error);
});*/

    try {
      responsePerfilCargo = await this.$store.getters.fetchGet({
        path: `perfilcargo/consultarPerfilCargoAll/${cargo.id}`,
      });
      perfilCargo = await responsePerfilCargo.json();
      // console.log(
      //   "Perfil sin responsabilidadesSSSSSSSSSSSSSSSSSS",
      //   responsePerfilCargo
      // );
      this.perfilCargo = perfilCargo;
    } catch (error) {
      console.log("Perfil sin responsabilidades", error);
    }

    // this.nivelCompetenciaComplejidadText = this.ListNivelCompetenciaComplejidad.filter((x) => x.id == perfilCargo.perfilCargo.nivelCompetenciaComplejidadId)[0].descripcion;

    let nivel = this.ListNivelCompetenciaComplejidad.filter(
      (x) => x.id == perfilCargo.perfilCargo.nivelCompetenciaComplejidadId
    );

    if (nivel.length > 0) {
      this.nivelCompetenciaComplejidadText =
        "Nivel " + nivel[0].id + ": " + nivel[0].descripcion;
    }

    this.$forceUpdate();

    // console.log('perfilCargo', perfilCargo);
    this.$store.getters
      .fetchGet({ path: `Cargo/ListCargo/${empresa.id}` })
      .then((response) => response.json())
      .then((result) => {
        this.todosLosCargos = result;
        // console.log('todosLosCargos', result);
      });

    this.$store.getters
      .fetchGet({
        path: `CargosAceptados/ListaPorPersonaYPerfil/${id}/${this.perfilCargo.perfilCargo.id}`,
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status == 404) {
          this.perfilCargoPersonaAceptado = false;
          throw new Error("");
        }
      })
      .then((result) => {
        this.perfilCargoPersonaAceptado = result;
      })
      .catch((error) => {
        console.error("Controlado", error);
      });

    //abr 02062024
    if (
      this.reponseUbicacion.descripcion == "PAYNET" ||
      this.reponseUbicacion.descripcion == "GSE"
    ) {
      this.$store.getters
        .fetchGet({ path: `CargoCompetencia/GetFuncionesGeneralesGSE` })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se han encontrado funciones generales");
        })
        .then((result) => {
          if (this.reponseUbicacion.descripcion == "PAYNET") {
            result = result.filter((x) => x.gseSucursal.includes("P"));
            this.funcionesGenerales = result;
          } else {
            result = result.filter((x) => x.gseSucursal.includes("G"));
            this.funcionesGenerales = result;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.$store.getters
        .fetchGet({
          path: `CargoCompetencia/GetFuncionesGeneralesByCargoId/${this.cargo.id}`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se han encontrado funciones generales");
        })
        .then((result) => {
          this.funcionesGenerales = result;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  data() {
    return {
      reponseUbicacion: {},
      empresa: {},
      perfilCargoPersonaAceptado: null,

      cargo: {},
      CargoId: null,
      perfilCargo: {},
      competencias: [],
      todosLosCargos: [],
      loader: false,
      funcionesGenerales: [],

      funcionesDeResponsabilidad: [],
      nivelCompetenciaComplejidadText: null,
      ListNivelCompetenciaComplejidad: [],
      sucursal: {},
      sucursalId: null,
      listaSucursales: [],
      cargosPorSucursal: [],
    };
  },
  methods: {
    async obtenerSucursales() {
      try {
        // console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.empresa.id,
        });
        // console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.listaSucursales = res;
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
        console.log("err", error);
      }
    },
    async ObtenerCargosPorSucursal() {
      try {
        this.cargosPorSucursal = this.todosLosCargos.filter(
          (x) => x.sucursalId == this.sucursalId
        );
        this.CargoId = null;
      } catch (error) {
        this.cargosPorSucursal = [];
        console.log("err", error);
      }
    },
    async ObtenerPerfilCargoById() {
      this.perfilCargo = {};

      /*this.cargo = {}
      this.competencias = []
      this.loader = false
      this.funcionesGenerales = []
      this.funcionesDeResponsabilidad = []
      this.nivelCompetenciaComplejidadText = null
      this.ListNivelCompetenciaComplejidad = []*/

      let { empresa } = JSON.parse(localStorage.setPersonaAct);

      let persona = JSON.parse(localStorage.setPersonaAct);
      let ubicacionPersonaData = await this.$store.dispatch("hl_get", {
        path: "PersonaUbicacion/GetByPersona/" + persona.id,
      });
      this.sucursal = ubicacionPersonaData.sucursal;

      this.empresa = empresa;

      // const reponseUbicacion = await this.$store.getters.fetchGet({ path: `PersonaUbicacion/GetByPersonaId/${personaId}` });
      // let responseUbicacion = await reponseUbicacion.json();
      const cargo = this.CargoId;
      // this.reponseUbicacion = responseUbicacion.sucursal;
      // console.log(cargo);
      await this.getFuncionesResponsabilidadYCompetencias(cargo);
      //await this.getNivelCompetenciaComplejidad();

      const responseCargoEditado = await this.$store.getters.fetchGet({
        path: `Cargo/todoSobreElCargo/${cargo}`,
      });
      const cargoIntroduction = await responseCargoEditado.json();
      this.cargo = cargoIntroduction.cargo;
      this.competencias = cargoIntroduction.competencias;
      // console.log('competencias', cargoIntroduction.competencias);

      let responsePerfilCargo = null;
      let perfilCargo = null;

      try {
        responsePerfilCargo = await this.$store.getters.fetchGet({
          path: `perfilcargo/consultarPerfilCargoAll/${this.CargoId}`,
        });

        perfilCargo = await responsePerfilCargo.json();
        console.log("data perfilcargo ", perfilCargo);
        if (perfilCargo.status == 404) {
          Swal.fire(
            "Cargo sin perfil",
            "Este cargo no tiene ningún perfil asociado en el sistema.",
            "warning"
          );

          this.competenciasDelCargo = [];
          this.funcionesGenerales = [];
          this.funcionesDeResponsabilidad = [];
          this.perfilCargo = {};
          this.$forceUpdate();
          return;
        } else {
          // console.log(
          //   "Perfil sin responsabilidadesSSSSSSSSSSSSSSSSSS",
          //   responsePerfilCargo
          // );
          this.perfilCargo = perfilCargo;
        }
      } catch (error) {
        alert("error");
        console.log("Perfil sin responsabilidades", error);
      }
      // this.nivelCompetenciaComplejidadText = this.ListNivelCompetenciaComplejidad.filter((x) => x.id == perfilCargo.perfilCargo.nivelCompetenciaComplejidadId)[0].descripcion;

      let nivel = this.ListNivelCompetenciaComplejidad.filter(
        (x) => x.id == perfilCargo.perfilCargo.nivelCompetenciaComplejidadId
      );

      if (nivel.length > 0) {
        this.nivelCompetenciaComplejidadText =
          "Nivel " + nivel[0].id + ": " + nivel[0].descripcion;
      }

      this.$forceUpdate();

      //abr 02062024
      /*if (this.reponseUbicacion.descripcion == "PAYNET" || this.reponseUbicacion.descripcion == "GSE") {
    this.$store.getters
      .fetchGet({ path: `CargoCompetencia/GetFuncionesGeneralesGSE` })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("No se han encontrado funciones generales");
      })
      .then((result) => {
        if (this.reponseUbicacion.descripcion == "PAYNET") {
          result = result.filter((x) => x.gseSucursal.includes("P"));
          this.funcionesGenerales = result;
        } else {
          result = result.filter((x) => x.gseSucursal.includes("G"));
          this.funcionesGenerales = result;
        }
      })
      .catch((error) => {
        console.error(error);
      });
/* }else {*/
      this.$store.getters
        .fetchGet({
          path: `CargoCompetencia/GetFuncionesGeneralesByCargoId/${this.CargoId}`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se han encontrado funciones generales");
        })
        .then((result) => {
          this.funcionesGenerales = result;
        })
        .catch((error) => {
          console.error(error);
        });
      /* }*/
    },

    monthToYear(month) {
      if (month < 12) {
        return `${month} meses`;
      } else {
        let years = Math.floor(month / 12);
        let months = month % 12;
        return `${years} años ${months} meses`;
      }
    },
    getNivelCompetenciaComplejidad() {
      this.$store.getters
        .fetchGet({
          path: `NivelCompetenciaComplejidad/ListNivelCompetenciaComplejidad/`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al obtener las competencias del cargo");
        })
        .then((result) => {
          this.ListNivelCompetenciaComplejidad = result;
        })
        .catch((error) => console.log(error));
    },
    nombreDelCargoById(cargoId = "") {
      if (this.todosLosCargos.length == 0) return;
      return this.todosLosCargos.find((c) => c.id == cargoId);
    },
    async getFuncionesResponsabilidadYCompetencias(cargoId) {
      // ? Quizás este método pueda mejorar desde el backend

      const resp = await this.$store.getters.fetchGet({
        path: `PerfilCargo/FuncionesDeResponsabilidadByCargo/${cargoId}`,
      });
      this.funcionesDeResponsabilidad = await resp.json();

      const respp = await this.$store.getters.fetchGet({
        path: `PerfilCargo/FuncionesDeResponsabilidadByCargoYCompetencias/${cargoId}`,
      });
      const funcionesYCompetencias = await respp.json();

      this.funcionesDeResponsabilidad.forEach((funcion) => {
        funcion.responsabilidades = funcionesYCompetencias.filter(
          (f) => f.funcionId == funcion.id
        );
      });
    },
  },
  computed: {
    ...mapGetters(["toFormatDate"]),
    competenciasDelCargo() {
      return this.competencias.filter(
        (c) => c.competencia.tipoCompetencia.descripcion != "Responsabilidad"
      );
    },
    funcionesYResponsabilidades() {
      const responsabilidades = this.competencias.filter(
        (c) => c.competencia.tipoCompetencia.descripcion == "Responsabilidad"
      );

      let funciones = {};

      responsabilidades.forEach((responsabilidad) => {
        if (responsabilidad.cargoFunciones != null) {
          if (
            Object.keys(funciones).includes(
              responsabilidad.cargoFunciones.nombre
            )
          ) {
            funciones[responsabilidad.cargoFunciones.nombre].push(
              responsabilidad
            );
          } else {
            funciones[responsabilidad.cargoFunciones.nombre] = [
              responsabilidad,
            ];
          }
        } else {
          if (Object.keys(funciones).includes("Sin función")) {
            funciones["Sin función"].push(responsabilidad);
          } else {
            funciones["Sin función"] = [responsabilidad];
          }
        }
      });

      // console.log('responsabilidades', responsabilidades);

      return funciones;
    },
    funcionesTipoG() {
      if (this.item.funciones) {
        return this.item.funciones.filter((f) => f.tipo == "G");
      } else {
        return [];
      }
    },
    // funcionesTipoNotG() {
    //   if (this.item.funciones) {
    //     return this.item.funciones.filter(f => f.tipo != 'G')
    //   }
    //   else {
    //     return []
    //   }
    // }
  },
  filters: {
    getNombre(cargo) {
      if (cargo) {
        return cargo.nombre;
      } else {
        return "";
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <b-row class="justify-content-lg-center">
      <div class="col-xl-9">
        <card style="height: 80%">
          <div class="row px-4 justify-content-center">
            <div class="col-md-6 text-center">
              <div style="padding: 3% 0">
                <div style="padding: 10% 0">
                  <span class="h2 text-white"
                    ><b>PERFILES Y FUNCIONES DE LOS CARGOS</b><br
                  /></span>
                  <span class="h3 text-light"><b>Talento Humano</b></span>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4">
        <div class="mb-3">
          <label for=" " class="form-label">Sucursal</label>
          <div
            style="
              background: transparent;
              padding: 0rem;
              border-radius: 0.3rem;
            "
          >
            <v-select
              required
              @input="ObtenerCargosPorSucursal()"
              class="style-chooser"
              :reduce="(value) => value.id"
              v-model="sucursalId"
              :options="listaSucursales"
              label="descripcion"
              placeholder="-- selecciona una opción --"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for=" " class="form-label">Cargo</label>
          <div
            style="
              background: transparent;
              padding: 0rem;
              border-radius: 0.3rem;
            "
          >
            <v-select
              required
              @input="ObtenerPerfilCargoById()"
              class="style-chooser"
              :reduce="(value) => value.id"
              v-model="CargoId"
              :options="cargosPorSucursal"
              label="nombre"
              placeholder="-- selecciona una opción --"
            ></v-select>
          </div>
        </div>
      </div>
    </b-row>

    <card class="py-4 px-4">
      <b-row class="my-2">
        <b-col sm v-if="perfilCargo != {}">
          <h5 class="mb-3">Información del cargo</h5>
          <div v-if="Object.keys(cargo).length">
            <p>
              <b>Nombre del cargo:</b>
              {{ nombreDelCargoById(cargo.id) | getNombre }}
            </p>
            <!-- <p>
                  <b>Proceso:</b> {{ item.proceso }}
                </p> -->
            <p>
              <b>Responsable inmediato:</b>
              {{ nombreDelCargoById(cargo.cargoDependienciaId) | getNombre }}
            </p>
            <p><b>Nivel:</b> {{ cargo.tipoCargo.descripcion }}</p>
          </div>
        </b-col>
      </b-row>
    </card>
    <card v-if="perfilCargo != {}" class="py-4 px-4">
      <div class="mb-4">
        <h5>Objetivo del cargo</h5>
        <!-- <b-list-group v-if="perfilCargo != []"> -->

        <!-- </b-list-group>-->
        <b-list-group v-if="Object.keys(perfilCargo).length">
          <b-list-group-item>
            <p><b>Objetivo:</b> {{ perfilCargo.perfilCargo.objetivo }}</p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </card>
    <card v-if="perfilCargo != {}" class="py-4 px-4">
      <div>
        <div class="mb-4">
          <b-row>
            <b-col sm>
              <h5>PERFIL EDUCACIÓN</h5>
              <b-list-group>
                <b-list-group-item
                  v-for="(etapa, index) in perfilCargo.formacion"
                  :key="'formacion' + index"
                >
                  <p><b>Titulo:</b> {{ etapa.descripcion }}</p>
                  <!-- <p><b>Indispensable:</b> {{ etapa.indispensable ? "Si" : "No" }}</p>
                  <p><b>Deseable:</b> {{ etapa.deseable ? "Si" : "No" }}</p> -->
                </b-list-group-item>

                <b-list-group-item
                  v-for="(etapa, index) in perfilCargo.educacion"
                  :key="'educacion' + index"
                >
                  <p><b>Area y/o especialidad:</b> {{ etapa.especialidad }}</p>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </div>

        <div class="mb-4">
          <h5>
            {{ empresa.nombre == "Grupo GSE" ? "Habilidades" : "Competencias" }}
          </h5>
          <b-list-group>
            <b-list-group-item
              v-for="competencia in competenciasDelCargo"
              :key="competencia.titulo"
            >
              <div class="display-grid">
                <div class="display-grid-body">
                  <p><b>Titulo:</b> {{ competencia.competencia.titulo }}</p>
                  <p>
                    <b>Descripción:</b>
                    {{ competencia.competencia.descripcion }}
                  </p>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- ! AQuiiii abr:18062024 he agregado hasownproperty porque se generaba error-->
        <div class="mb-4">
          <h5>Funciones y responsabilidades del cargo</h5>
          <!-- {{ funcionesDeResponsabilidad }} -->
          <b-list-group>
            <b-list-group-item
              v-for="(funcion, idx) in funcionesDeResponsabilidad"
              :key="'funcion' + idx"
            >
              <p class="mb-1" style="font-size: 1.05rem">
                {{ funcion.nombre }}
              </p>
              <ul style="padding-left: 0.6rem">
                <!-- <li class="text-justify" v-if="funcion.responsabilidades.length == 0">No hay responsabilidades</li> -->

                <li
                  class="text-justify"
                  v-for="(responsabilidad, index) in funcion.responsabilidades"
                  :key="`responsabilidad ${index}`"
                >
                  {{ responsabilidad.competenciaTitulo }}
                </li>
              </ul>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div class="mb-4">
          <h5>Funciones generales frente a los sistemas de gestión</h5>
          <p>
            (Gestión de Calidad, Gestión de Seguridad de la Información y
            Gestión de Seguridad y Salud en el Trabajo)
          </p>
          <div>
            <!-- TODO: Relacionar funciones en la creacion en Cargo_perfil -->
          </div>
          <b-list-group>
            <b-list-group-item
              v-for="funcion in funcionesGenerales"
              :key="'funcionG' + funcion.id"
            >
              <p>
                {{ funcion.nombre }}
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div class="mb-4">
          <h5>Observaciones</h5>
          <div v-if="Object.keys(perfilCargo).length">
            <b-list-group>
              <b-list-group-item>
                <p v-if="perfilCargo.perfilCargo.observaciones">
                  {{ perfilCargo.perfilCargo.observaciones }}
                </p>
                <p v-else>No hay observaciones</p>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<style scoped>
.circular--landscape {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  /* border-radius: 50%; */
  object-fit: contain;
  /* background-color: #fff */
}

.circular--landscape img {
  width: auto;
  height: 100%;
  margin-left: -50px;
  object-fit: contain;
}

.display-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
}

.display-grid-body {
  grid-column: 1 / span 10;
}

.display-grid-side {
  grid-column: 11 / span 2;
}

@media (max-width: 768px) {
  .display-grid {
    display: block;
  }

  .display-grid-body {
    grid-column: 1 / span 12;
  }

  .display-grid-side {
    grid-column: 1 / span 12;
  }
}
</style>
